import "./Consultation.css";
import { InlineWidget } from "react-calendly";

const Consultation = () => {
  return (
    <div className="app-calendly" id="calendly">
      <div className="description">
        <h2>Book a Free Consultation</h2>
        <p className="text">
          Ready to take your digital presence to the next level? Select a time
          that works best for you, and let's explore how we can bring your
          vision to life.
        </p>
      </div>
      <InlineWidget url="https://calendly.com/teamody/consultation" />
    </div>
  );
};

export default Consultation;
