import "./Hero.css";

const Hero = () => {
  return (
    <section className="app-hero">
      <div className="container">
        <div className="description">
          <h1>Crafting Digital Experiences That Elevate Your Brand</h1>
          <p className="text">
            We create beautifully designed, high-performance websites that not
            only reflect the essence of your brand but also deliver seamless
            user experiences. From clean, minimalistic layouts to fully
            customized functionalities, our solutions are crafted to engage your
            audience and drive your business forward.
          </p>
        </div>
        <div className="image-wrapper">
          <img src="/Hero.png" alt="Hero" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
