import "./ChangeGame.css";

const ChangeGame = () => {
  return (
    <section className="app-change-game">
      <div className="container">
        <h2>Your Website Should Change The Game.</h2>
        <p className="text">
          Your website should be more than just a digital presence — it should
          be a game-changer. Push boundaries, turn visitors into loyal customers
          and ideas into reality. Whether it's showcasing your brand or
          launching a powerful application, we build solutions that don't just
          keep up — they lead the way.
        </p>
      </div>
    </section>
  );
};

export default ChangeGame;
