import React, { useState } from "react";
import "./WhyUs.css"; // assuming you're using CSS for styling

const WhyUs = () => {
  const [openItem, setOpenItem] = useState(0);

  const toggleItem = (index) => {
    setOpenItem(openItem === index ? null : index);
  };

  const reasons = [
    {
      title: "Tailored For Your Goals",
      content:
        "Every project we take on is uniquely crafted to meet your business objectives, ensuring that your website drives real results.",
    },
    {
      title: "Cutting-Edge Technology",
      content:
        "We go beyond using the latest tools, integrating AI, LLMs, and scalable platforms to future-proof your digital presence and give you a competitive edge.",
    },
    {
      title: "User Experience First",
      content:
        "We design with your audience in mind, creating intuitive interfaces that engage and convert.",
    },
    {
      title: "Built to Grow",
      content:
        "Our solutions are built with scalability in mind, ensuring your website evolves alongside your business without limits.",
    },
  ];

  return (
    <section className="app-why-us">
      <div className="container">
        <div className="title">
          <h2>What Sets Us Apart</h2>
        </div>
        <div className="reasons">
          {reasons.map((item, index) => (
            <div
              className={`reason ${
                openItem === index ? "expanded" : "collapsed"
              }`}
              key={index}
            >
              <div className="item-header" onClick={() => toggleItem(index)}>
                <h3>{item.title}</h3>
                <button>{openItem === index ? "-" : "+"}</button>
              </div>
              {openItem === index && <p>{item.content}</p>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
