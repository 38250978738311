import { PopupWidget } from "react-calendly";
import "./App.css";

import WhyUs from "./WhyUs";
import StandOut from "./StandOut";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Hero from "./Hero";
import Header from "./Header";
import Services from "./Services";
import ChangeGame from "./ChangeGame";
import Vision from "./Vision";
import Consultation from "./Consultation";

function App() {
  return (
    <div className="app">
      <Header />
      <Hero />
      <Services />
      <ChangeGame />
      <Vision />
      <WhyUs />
      <StandOut />
      <FAQ />
      <Consultation />
      <Footer />
      {/* <PopupWidget
        url="https://calendly.com/teamody/consultation"
        rootElement={document.getElementById("root")}
        text="Got a question?"
        textColor="#fff"
        color="black"
      /> */}
    </div>
  );
}

export default App;
