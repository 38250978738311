import React, { useEffect, useState } from "react";
import { FaLightbulb, FaTools, FaSeedling } from "react-icons/fa";
import "./StandOut.css";

const StandOut = () => {
  const [visibleSection, setVisibleSection] = useState({});

  const sections = [
    {
      title: "Dream it",
      icon: <FaLightbulb />,
      content:
        "Every great project starts with a vision. We work with you to transform your ideas into a clear strategy, ensuring that your website reflects your goals and captures your audience.",
    },
    {
      title: "Build it",
      icon: <FaTools />,
      content:
        "From concept to code, we create sleek, high-performance websites and platforms that are custom-built to meet your business needs and deliver results.",
    },
    {
      title: "Grow it",
      icon: <FaSeedling />,
      content:
        "Your digital presence should evolve with your business. We provide ongoing support and innovative solutions to help your website scale and grow, keeping you ahead of the curve.",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const newVisibleSection = {};
      document.querySelectorAll(".section").forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight * 0.8) {
          newVisibleSection[index] = true;
        }
      });
      setVisibleSection(newVisibleSection);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section className="app-stand-out">
      <div className="title">
        <h2>
          <span className="highlight">Make it stand out</span>
        </h2>
      </div>
      <div className="sections">
        {sections.map((section, index) => (
          <div
            key={index}
            className={`section ${visibleSection[index] ? "visible" : ""}`}
          >
            <div className="section-header">
              <div className="icon">{section.icon}</div>
              <h3 className="section-title">{section.title}</h3>
            </div>
            <div className="section-content-wrapper">
              <p className="section-content">{section.content}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StandOut;
