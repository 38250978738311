import "./Footer.css";

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="left">
        <h2>teamody</h2>
        <p>© 2024. All Rights Reserved.</p>
      </div>
      <div className="right">
        <div className="location">
          <h3>Location</h3>
          <p>
            Maple Shade,
            <br /> New Jersey 08052
          </p>
        </div>
        <div className="email">
          <h3>Contact</h3>
          <p>info@teamody.com</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
