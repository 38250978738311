import "./Vision.css";

const Vision = () => {
  const scrollToCalendar = () => {
    document.getElementById("calendly").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <section className="app-vision">
      <div className="image">
        <img src="/Vision.png" alt="Vision" />
      </div>
      <div className="description">
        <h2>Your Vision, Our Expertise</h2>
        <div className="text-wrapper">
          <p className="text">
            We transform your ideas into digital experiences that not only look
            great but deliver real results. With a perfect blend of creativity
            and precision, we bring your vision to life—seamlessly.
          </p>
          <p className="text">
            Whether it’s a sleek brand site or a powerful platform, our
            solutions are built for impact and designed to scale. We don’t just
            meet expectations—we exceed them.
          </p>
        </div>
        <button onClick={() => scrollToCalendar()}>
          Start Your Project Today
        </button>
      </div>
    </section>
  );
};

export default Vision;
