import React from "react";
import "./FAQ.css";

const FAQ = () => {
  const faqs = [
    {
      title: "What types of businesses do you work with?",
      content:
        "We work with a diverse range of businesses, from startups to established enterprises. Whether you need a sleek brand website or a complex platform, we tailor our solutions to fit your specific needs.",
    },
    {
      title: "How long does it take to build a website or platform?",
      content:
        "The timeline depends on the complexity of your project. On average, a fully customized website can take 4-8 weeks, while larger platforms may take longer. We'll provide a detailed timeline after understanding your goals and requirements.",
    },
    {
      title: "Do you offer ongoing support after the project is completed?",
      content:
        "Yes! We provide ongoing support and maintenance packages to ensure your website or platform continues running smoothly and stays updated with the latest technologies.",
    },
    {
      title: "Can you integrate custom features into my existing website?",
      content:
        "Absolutely. We specialize in integrating AI/LLM solutions and custom features into both new and existing websites, enhancing functionality and user experience.",
    },
    {
      title: " How do you ensure the quality of the projects you deliver? ",
      content:
        "We follow a rigorous quality assurance process, including thorough testing, code reviews, and regular feedback sessions. This helps us ensure that the final product is polished, reliable, and exceeds your expectations.",
    },
    {
      title: "What's your process for starting a new project?",
      content:
        "We begin with an in-depth consultation to understand your goals and vision. From there, we create a project roadmap, working closely with you from concept to launch to ensure everything aligns with your business objectives.",
    },
  ];

  return (
    <section className="app-faq">
      <div className="title">
        <h2>FAQs</h2>
      </div>
      <div className="container">
        <div className="image">
          <img src="/About-us.png" alt="FAQ" />
        </div>
        <div className="cards">
          {faqs.map((faq, index) => (
            <div className="card" key={index}>
              <div className="card-inner">
                <div className="card-front">
                  <h3>{faq.title}</h3>
                </div>
                <div className="card-back">
                  <p>{faq.content}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
