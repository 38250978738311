import "./Header.css";
import { PopupModal } from "react-calendly";
import { useState } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="app-header">
      <h2 className="logo">teamody</h2>
      <button onClick={() => setIsOpen(true)}>Let's Talk</button>

      <PopupModal
        url="https://calendly.com/teamody/consultation"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")}
        className="calendly-modal"
      />
    </header>
  );
};

export default Header;
