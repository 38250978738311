import "./Services.css";

const Services = () => {
  return (
    <section className="app-services">
      <h2>Our Services</h2>
      <div className="services">
        <div className="service">
          <div className="image">
            <img src="/Art-design.png" alt="Brand" />
          </div>
          <div>
            <h3>Brand & Product Websites</h3>
            <p className="headline">Tell Your Story</p>
            <p className="description">
              Stunning, high-impact websites that tell your brand’s story and
              showcase your products. Perfect for businesses looking to elevate
              their online presence and create lasting impressions with sleek,
              responsive designs.
            </p>
          </div>
        </div>
        <div className="service">
          <div className="image">
            <img src="/Web-app.png" alt="Web application" />
          </div>
          <div>
            <h3>Platforms & Web Applications</h3>
            <p className="headline">Built For Performance</p>
            <p className="description">
              Powerful, scalable platforms tailored to your business needs. From
              membership platforms to booking systems, we build custom web
              applications that streamline operations and enhance user
              experience.
            </p>
          </div>
        </div>
        <div className="service">
          <div className="image">
            <img src="/Integrations.png" alt="Integrations" />
          </div>
          <div>
            <h3>AI Powered Solutions & Integrations</h3>
            <p className="headline">Powered By Intelligence</p>
            <p className="description">
              Enhance your platform with AI and large language models (LLMs). We
              integrate features like chatbots, personalized recommendations,
              and advanced data processing to create smarter, more efficient
              user experiences.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
